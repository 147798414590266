import React from "react"
import { Link } from "react-router-dom"

function Footer() {
  return (
    <footer class="flex">
      <div className="w-3/5 flex justify-center">
        <div className="mb-2">
          <a
            href="https://metsafeatm.com"
            className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 transition duration-150 ease-in-out"
            target="_blank"
            title="MetSafe ATM website"
          >
            <h6 className="text-gray-800 font-medium mb-2">By</h6>
            <img src={require("../images/logo.png")} width={128} height={128} />
          </a>
        </div>
      </div>
      <div class="w-2/5 mx-auto">
        <h6 className="text-gray-800 font-medium mb-2">Follow Us</h6>
        <div class="flex">
          <a
            href="https://twitter.com/MetSafe_ATM"
            className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 transition duration-150 ease-in-out"
            aria-label="Twitter"
          >
            <svg
              className="w-12 h-12 fill-current"
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z" />
            </svg>
          </a>
          <a
            href="https://fr.linkedin.com/company/metsafe"
            className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 transition duration-150 ease-in-out"
            aria-label="Linkedin"
          >
            <svg
              className="w-12 h-12 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32 "
            >
              <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
            </svg>
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
