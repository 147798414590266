import React from "react"

function Testimonials() {
  return (
    <section className="relative">
      {/* Illustration behind content */}
      <div
        className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none -mb-32"
        aria-hidden="true"
      >
        <svg
          width="1760"
          height="518"
          viewBox="0 0 1760 518"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient
              x1="50%"
              y1="0%"
              x2="50%"
              y2="100%"
              id="illustration-02"
            >
              <stop stopColor="#FFF" offset="0%" />
              <stop stopColor="#EAEAEA" offset="77.402%" />
              <stop stopColor="#DFDFDF" offset="100%" />
            </linearGradient>
          </defs>
          <g
            transform="translate(0 -3)"
            fill="url(#illustration-02)"
            fillRule="evenodd"
          >
            <circle cx="1630" cy="128" r="128" />
            <circle cx="178" cy="481" r="40" />
          </g>
        </svg>
      </div>

      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="h2 mb-4">Supported by SESAR Engage</h2>
            <p className="text-xl text-gray-600" data-aos="zoom-y-out">
              Initial R&D for VigiAero has been funded through two SESAR ENGAGE
              projects{" "}
              <a
                href="https://engagektn.com/thematic-challenges/"
                target="_blank"
                class="text-gray-600 hover:text-gray-900 underline"
              >
                Efficient provision and use of meteorological information in
                ATM"
              </a>{" "}
              Challenge.
            </p>
          </div>
          <div className="max-w-3xl mx-auto mt-20" data-aos="zoom-y-out">
            <div className="relative flex items-start border-2 border-gray-200 rounded bg-white">
              <div className="text-center px-12 py-8 pt-20 mx-4 md:mx-0">
                <div className="absolute top-0 -mt-8 left-1/2 transform -translate-x-1/2">
                  <img
                    className="relative rounded-full"
                    src={require("../images/logo_engage.png")}
                    width="96"
                    height="96"
                  />
                </div>
                <blockquote className="text-xl font-medium mb-4">
                  The European weather hazards forecast is based on an
                  innovative multimodel approach derived from the ‘MET-Enhanced
                  ATFCM’ project. VigiAero algorithm is built on the ‘WIPA’
                  project outputs.
                </blockquote>
              </div>
            </div>
          </div>
          <section class="bg-white border-b py-12 ">
            <div class="container mx-auto flex flex-wrap items-center justify-between pb-12">
              <h2 class="w-full my-2 text-xl font-black leading-tight text-center text-gray-800 lg:mt-8 pb-8">
                Current Users
              </h2>
              <div class="w-full mb-4">
                <div class="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
              </div>

              <div class="flex">
                <div class=" flex-auto w-1/2 mx-8">
                  <div class="w-full lg:max-w-full lg:flex">
                    <div class="h-48 lg:h-auto lg:w-48 flex-none  rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden">
                      <a
                        href="https://www.ecologie.gouv.fr/direction-generale-laviation-civile-dgac"
                        target="_blank"
                      >
                        <img
                          src={require("../images/dsna_logo.png")}
                          width={128}
                        />
                      </a>
                    </div>
                    <div class=" p-4 flex flex-col justify-between leading-normal">
                      <div class="mb-8">
                        <div class="text-gray-900 font-bold text-xl mb-2">
                          DSNA
                        </div>
                        <p class="text-gray-700 text-base">
                          The first trials start in May 2021 within the ATC room
                          of two French Upper Area Control centers
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class=" flex-auto w-1/2 mx-8">
                  <div class=" w-full lg:max-w-full lg:flex">
                    <div class="h-48 lg:h-auto lg:w-48 flex-none  rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden">
                      <a href="https://www.eurocontrol.int" target="_blank">
                        <img
                          src={require("../images/eurocontrol_logo.png")}
                          width={128}
                        />
                      </a>
                    </div>
                    <div class=" p-4 flex flex-col justify-between leading-normal">
                      <div class="mb-8">
                        <div class="text-gray-900 font-bold text-xl mb-2">
                          EUROCONTROL
                        </div>
                        <p class="text-gray-700 text-base">
                          Use VigiAero for NM prototyping activity
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  )
}

export default Testimonials
